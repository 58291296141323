<template>
  <div>
    <!-- Title -->
    <b-card-title class="text-primary mb-3">Checkout</b-card-title>

    <!-- Alert -->
    <b-alert variant="info" dismissible show fade>
      An OTP has been sent to your registered mobile number. Please enter the
      OTP and a new password to reset your credentials.
    </b-alert>

    <reset-password :otpData="otpData" @success="successReset" />

    <!-- Success Modal -->
    <b-modal
      title="Success"
      v-model="showSuccessAlert"
      size="sm"
      hide-footer
      no-close-on-backdrop
      centered
      @close="onSuccessAlertClose"
    >
      Password reset successful.
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { authenticate } from "../../../../../apis";
import ResetPassword from "../../../../../components/auth/ResetPassword.vue";

export default {
  components: { ResetPassword },
  props: ["store"],
  created() {
    if (!this.otpData) {
      // no otp data found, return to login
      this.$router.push({ name: "Cart" });
      return;
    }
  },
  data() {
    return {
      routeParams: this.$route.params,

      showSuccessAlert: false,
      passwordSet: null,
    };
  },
  computed: {
    otpData() {
      return this.routeParams.otpData;
    },
    mobile() {
      return this.routeParams.mobile;
    },
  },
  methods: {
    ...mapActions({
      saveToken: "login/saveToken",
    }),
    successReset(data) {
      this.passwordSet = data.password;
      this.showSuccessAlert = true;
    },
    onSuccessAlertClose() {
      const mobile = this.mobile;
      const password = this.passwordSet;
      // directly perform login, with mobile number and new password
      authenticate({ mobile, password }).then((data) => {
        this.saveToken(data.token).then(() => {
          // redirect to place order screen
          this.$router.replace({ name: "CartPlaceOrder" });
        });
      });
    },
  },
};
</script>
