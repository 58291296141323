<template>
  <b-form @submit.prevent="onSubmit" class="pt-3">
    <b-form-group
      :invalid-feedback="
        getErrorMessage(errors, 'mobileOtp') ||
          getErrorMessage(errors, 'message')
      "
      :state="
        getErrorState(errors, 'mobileOtp') || getErrorState(errors, 'message')
      "
    >
      <b-form-input
        :state="
          getErrorState(errors, 'mobileOtp') || getErrorState(errors, 'message')
        "
        type="number"
        id="mobileOtp"
        v-model="form.mobileOtp"
        placeholder="Mobile OTP"
        trim
      />
    </b-form-group>

    <form-password
      :invalid-feedback="getErrorMessage(errors, 'password')"
      :state="getErrorState(errors, 'password')"
      id="password"
      v-model="form.password"
      placeholder="New Password"
    ></form-password>

    <b-button
      type="submit"
      variant="primary"
      class="text-uppercase text-white p-2 mt-4"
      pill
      block
    >
      Submit
    </b-button>
  </b-form>
</template>

<script>
import { resetForgotPassword } from "../../apis";
export default {
  props: {
    otpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        mobileOtp: "",
        password: null,
      },
      errors: null,
    };
  },
  methods: {
    onSubmit() {
      this.errors = null;
      const dataToSend = {
        ...this.otpData,
        ...this.form,
      };

      resetForgotPassword(dataToSend)
        .then((data) => {
          this.$emit("success", { ...data, password: this.form.password });
        })
        .catch(({ data }) => {
          this.errors = data.errors;
        });
    },
  },
};
</script>
